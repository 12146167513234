import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useEffect, useRef, useState } from 'react';
import getConfig from 'next/config';
import { useClient } from '../context';
import { ChatNotificationsQuery } from '../query';
import useChatNotificationsChecker from './useChatNotificationsChecker';
var getNow = function getNow() {
  return new Date().toISOString();
};
var ONE_SECOND_IN_MILLIS = 60000;
var useChatNotifications = function useChatNotifications(_ref) {
  var _publicRuntimeConfig$;
  var userId = _ref.userId,
    conversationId = _ref.conversationId,
    staticProps = _ref.staticProps;
  var _getConfig = getConfig(),
    publicRuntimeConfig = _getConfig.publicRuntimeConfig;
  var pollInterval = (publicRuntimeConfig == null ? void 0 : (_publicRuntimeConfig$ = publicRuntimeConfig.chatSeller) == null ? void 0 : _publicRuntimeConfig$.pollInterval) || ONE_SECOND_IN_MILLIS;
  var client = useClient();
  var queryRef = useRef();
  var lastUpdated = useRef(getNow());
  var checkerResult = useChatNotificationsChecker({
    staticProps: staticProps,
    userId: userId
  });
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    result = _useState2[0],
    setResult = _useState2[1];
  useEffect(function () {
    queryRef.current = client.watchQuery({
      fetchPolicy: 'network-only',
      query: ChatNotificationsQuery,
      variables: {
        channel: staticProps.channel,
        customerRefKey: userId,
        organization: staticProps.organization
      }
    });
  }, []);
  var refetch = useCallback(function () {
    queryRef.current.refetch({
      channel: staticProps.channel,
      customerRefKey: userId,
      organization: staticProps.organization,
      startsAt: lastUpdated.current
    }).then(function (response) {
      var _response$data;
      var notifications = (response == null ? void 0 : (_response$data = response.data) == null ? void 0 : _response$data.getNotifications) || [];
      var hasConversation = !!(notifications == null ? void 0 : notifications.find(function (item) {
        return item.conversationId === conversationId;
      }));
      if (!hasConversation && conversationId) {
        lastUpdated.current = getNow();
      }
      setResult({
        notifications: notifications
      });
    });
  }, []);
  useEffect(function () {
    var _checkerResult$result;
    var intervalId;
    if ((_checkerResult$result = checkerResult.result) == null ? void 0 : _checkerResult$result.hasNewMessages) {
      refetch();
      intervalId = setInterval(refetch, pollInterval);
    }
    return function () {
      clearInterval(intervalId);
    };
  }, [checkerResult.result]);
  return {
    loading: checkerResult.loading,
    notificationsStatus: checkerResult.result,
    result: result
  };
};
export default useChatNotifications;