import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
export var SECURITY_MESSAGE = {
  content: [{
    kind: 'text',
    value: 'Sempre faça transações dentro das plataformas oficiais do Magalu para evitar golpes. '
  }, {
    kind: 'link',
    label: ' Saiba Mais',
    target: '#'
  }],
  fromUser: {
    avatar: '',
    fullName: 'Magalu',
    type: 'system',
    username: 'Magalu'
  },
  id: 'PRIVACY',
  kind: 'alert',
  moderation: {
    status: 'APPROVED',
    whenAt: null
  },
  whenAt: null
};
export var getFirstValidMessage = function getFirstValidMessage(messages) {
  return messages == null ? void 0 : messages.find(function (message) {
    var _message$moderation;
    return ['REJECTED', 'APPROVED'].includes((_message$moderation = message.moderation) == null ? void 0 : _message$moderation.status);
  });
};
export var normalizePagination = function normalizePagination(meta, results, previousPage) {
  var size = results == null ? void 0 : results.length;
  if (!size) {
    return meta;
  }
  var lastItem = results[size - 1];
  var next = {
    pageStartMessageId: lastItem.id,
    pageTo: -1
  };
  var getPrevious = function getPrevious() {
    var message = getFirstValidMessage(results);
    var pageStartMessageId = (message == null ? void 0 : message.id) || (previousPage == null ? void 0 : previousPage.pageStartMessageId);
    if (!pageStartMessageId) {
      return null;
    }
    return {
      pageStartMessageId: pageStartMessageId,
      pageTo: 1
    };
  };
  return _extends({}, meta, {
    links: {
      next: meta.links.next ? next : null,
      previous: meta.links.previous ? getPrevious() : null
    }
  });
};
export var prependSafetyMessage = function prependSafetyMessage(dataset) {
  var data = (dataset == null ? void 0 : dataset.data) || [];
  var messages = [_extends({}, SECURITY_MESSAGE)].concat(_toConsumableArray(data));
  return _extends({}, dataset, {
    data: messages
  });
};