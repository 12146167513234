import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import { useState, useEffect, useRef, useCallback } from 'react';
import { ChatConversationsQuery } from '../query/chatConversations';
import { useClient } from '../context';
import { getDateDistance } from '@magalu/mixer-utils';
import useChatNotifications from './useChatNotifications';
export var getFormattedItems = function getFormattedItems(results) {
  if (!results) {
    return [];
  }
  return results.map(function (result) {
    return _extends({}, result, {
      lastInteractionAt: getDateDistance(result.lastInteractionAt)
    });
  });
};
export var updateCacheData = function updateCacheData(data, cache) {
  var result = cache.listConversations.results.map(function (cacheRecord) {
    var recordFound = data.find(function (dataRecord) {
      return cacheRecord.id === dataRecord.conversationId;
    });
    if (!recordFound) {
      return cacheRecord;
    }
    return _extends({}, cacheRecord, {
      lastInteractionAt: recordFound.dateLastMessage,
      lastMessage: {
        content: recordFound.resumeLastMessage
      },
      unreadFromCount: recordFound.countMessagesNew
    });
  });
  return {
    listConversations: _extends({}, cache.listConversations, {
      results: result
    })
  };
};
export var updateConversationsCache = function updateConversationsCache(_ref) {
  var provider = _ref.provider,
    cacheKey = _ref.cacheKey,
    dataset = _ref.dataset;
  var datasetCached = provider.readQuery(_extends({}, cacheKey));
  var data = updateCacheData(dataset, datasetCached);
  provider.writeQuery(_extends({}, cacheKey, {
    data: data
  }));
  return data;
};
var useChatConversations = function useChatConversations(_ref2) {
  var _data$meta, _data$meta$page, _data$meta2, _data$meta2$links, _notificationsResult$;
  var userId = _ref2.userId,
    search = _ref2.search,
    staticProps = _ref2.staticProps;
  var client = useClient();
  var notificationsResult = useChatNotifications({
    staticProps: staticProps,
    userId: userId
  });
  var queryRef = useRef();
  var _useState = useState({
      items: undefined,
      meta: undefined
    }),
    _useState2 = _slicedToArray(_useState, 2),
    data = _useState2[0],
    setData = _useState2[1];
  var _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    error = _useState4[0],
    setError = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    loading = _useState6[0],
    setLoading = _useState6[1];
  var currentOffset = (_data$meta = data.meta) == null ? void 0 : (_data$meta$page = _data$meta.page) == null ? void 0 : _data$meta$page.offset;
  var hasNextPage = ((_data$meta2 = data.meta) == null ? void 0 : (_data$meta2$links = _data$meta2.links) == null ? void 0 : _data$meta2$links.next) !== null;
  var loadingNotifications = notificationsResult.loading;
  var notifications = (_notificationsResult$ = notificationsResult.result) == null ? void 0 : _notificationsResult$.notifications;
  useEffect(function () {
    if (!loading && !loadingNotifications && (notifications == null ? void 0 : notifications.length) > 0) {
      var _newData$listConversa, _newData$listConversa2;
      var cacheKey = {
        query: ChatConversationsQuery,
        variables: {
          channel: staticProps.channel,
          fromUser: userId,
          organization: staticProps.organization
        }
      };
      var newData = updateConversationsCache({
        cacheKey: cacheKey,
        dataset: notifications,
        provider: client
      });
      setData({
        items: getFormattedItems(newData == null ? void 0 : (_newData$listConversa = newData.listConversations) == null ? void 0 : _newData$listConversa.results),
        meta: newData == null ? void 0 : (_newData$listConversa2 = newData.listConversations) == null ? void 0 : _newData$listConversa2.meta
      });
    }
  }, [loading, loadingNotifications, notifications]);
  var fetchMore = function fetchMore() {
    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    setLoading(true);
    queryRef.current.fetchMore({
      variables: {
        channel: staticProps.channel,
        fromUser: userId,
        offset: offset,
        organization: staticProps.organization,
        search: search
      }
    }).then(function (result) {
      setData(function (prevData) {
        var _result$data, _result$data$listConv, _result$data2, _result$data2$listCon;
        return {
          items: [].concat(_toConsumableArray(offset === 0 ? [] : prevData.items), _toConsumableArray(getFormattedItems(result == null ? void 0 : (_result$data = result.data) == null ? void 0 : (_result$data$listConv = _result$data.listConversations) == null ? void 0 : _result$data$listConv.results))),
          meta: result == null ? void 0 : (_result$data2 = result.data) == null ? void 0 : (_result$data2$listCon = _result$data2.listConversations) == null ? void 0 : _result$data2$listCon.meta
        };
      });
    })["catch"](function (err) {
      setError(err);
    }).then(function () {
      setLoading(false);
    });
  };
  var fetchNextPage = useCallback(function () {
    var offset = currentOffset + 1;
    fetchMore(offset);
  }, [currentOffset]);
  var handleOnRequest = function handleOnRequest(request) {
    request.then(function (result) {
      var _result$data3, _result$data3$listCon, _result$data4, _result$data4$listCon;
      setData({
        items: getFormattedItems(result == null ? void 0 : (_result$data3 = result.data) == null ? void 0 : (_result$data3$listCon = _result$data3.listConversations) == null ? void 0 : _result$data3$listCon.results),
        meta: result == null ? void 0 : (_result$data4 = result.data) == null ? void 0 : (_result$data4$listCon = _result$data4.listConversations) == null ? void 0 : _result$data4$listCon.meta
      });
    })["catch"](function (err) {
      setError(err);
    }).then(function () {
      setLoading(false);
    });
  };
  useEffect(function () {
    var query = client.watchQuery({
      query: ChatConversationsQuery,
      variables: {
        channel: staticProps.channel,
        fromUser: userId,
        organization: staticProps.organization
      }
    });
    handleOnRequest(query.result());
    queryRef.current = query;
  }, []);
  var refetch = useCallback(function () {
    var _queryRef$current;
    handleOnRequest((_queryRef$current = queryRef.current) == null ? void 0 : _queryRef$current.refetch());
  }, []);
  return {
    error: error,
    fetchMore: fetchMore,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    items: data.items,
    loading: loading,
    refetch: refetch
  };
};
export default useChatConversations;